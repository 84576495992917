<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const layout = computed(() => store.state.layout);
defineProps({
  card: {
    type: Object,
    required: true,
    title: String,
    description: String,
    links: {
      type: Array,
      label: String,
      route: String,
      color: String,
    },
  },
});
</script>
<template>
  <div v-show="layout !== 'landing'" class="card card-plain shadow-none" id="sidenavCard">
    <div class="p-3 card-body text-center w-100 pt-0">
      <img class="w-50 mx-auto" src="@/assets/img/logos/Dattenberg_wappen-200x232.png" alt="sidebar_illustration" />

      <h6 class="mb-0 text-dark up">Gemeinde Dattenberg</h6>

      <p class="text-xs font-weight-bold">Schönste Dorf am Rhein</p>
    </div>

    <a href="#" target="_blank" class="mb-3 btn btn-dark btn-sm w-100"> App öffnen </a>
    <div class="form-floating">
      <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
        <option selected value="1">Gemeinde Dattenberg</option>
        <option value="2">Linz am Rhein</option>
      </select>
      <label for="floatingSelect">Projekt wählen:</label>
    </div>
  </div>
</template>
