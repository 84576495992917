<template>
  <div class="row">
    <div class="col-8">
      <div class="card">
        <div class="card-header pb-0">
          <h6>Beitragsübersicht</h6>
        </div>
        <div class="card-body">
          <div class="">
            <h1>Müllsammelaktion 2023</h1>
            <div>
              <p>Vergangenen Samstag war es wieder soweit.</p>
              <p>
                Zusammen mit dem Junggesellenverein Dattenberg und zahlreichen freiwilligen Helfern haben wir vom Verkehrs- und Verschönerungsverein Dattenberg
                (VVD) die Wege rund um und im Dorf vom Müll befreit.
              </p>
              <p>
                Auch dieses Jahr ist wieder einiges zusammengekommen. Neben einigem Plastikmüll auf den Wegen wurde erneut einiges an Sondermüll aus den
                Waldstücken geholt. Die Bilder sprechen leider auch dieses Jahr wieder für sich.
              </p>
              <p>
                Ein Dank geht an alle fleißigen Helfer, die auch dieses Jahr wieder dazu beigetragen haben, unser Dorf ein wenig sauberer zu machen! Weitere
                Bilder findet ihr auf der <a href="#">Homepage des Verkehrs- und Verschönerungsverein Dattenberg (VVD)</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-header pb-0">
          <h6>Beitragsübersicht</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
          <img src="../../assets/img/carousel-1.jpg" class="ratio ratio-16x9 object-fit-cover" />
        </div>
      </div>
    </div>
  </div>
</template>
